









































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import Options from "@/models/vue/Options";
import Notification from "@/models/Notification";
import NotificationService from "@/services/NotificationService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Invoice from "@/models/Invoice";
import InvoiceService from "@/services/InvoiceService";
import InvoiceDialog from "@/components/InvoiceDialog.vue";
import LangModule from "@/store/LangModule";
import Handler from "@/handlers/Handler";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import SnackbarModule from "@/store/SnackbarModule";

@Component({components:{InvoiceDialog}})
export default class InvoicesView extends Vue {
	lang: any = getModule(LangModule).lang
	dialog: boolean = false
	invoices: Invoice[] = []
	invoice: Invoice = new Invoice()
	page: number = 1
	pageCount: number = 0
	loading: boolean = false
	itemsPerPage: number = 10
	totalItems: number = 0
	options: Options = new Options()
	search: string = ""
	picker: string = ""
	dates: string[] = [this.picker]
	lessThan: number | null = null
	greaterThan: number | null = null
	headers = [
		{ align: "center", text: "Xgest Id", value: "connectorId", width: "auto" },
		{ align: "center", text: this.lang.date, value: "date", width: "350px" },
		{ align: "center", text: this.lang.total, value: "total", width: "350px" },
	]
	sortBy: string[] = []
	sortDesc: boolean[] = []

	sortDateDesc: boolean = true
	sortCodeDesc: boolean = true

	async synchronize() {
    try {
      let lmao: SingleItem<null> = {item: null}
      await Handler.getItem(this, lmao, () => InvoiceService.synchronizeInvoices2())
    } catch (e) { console.log(e) }
  }

	getInvoicesCSV() {
		InvoiceService.getInvoicesCSV(
			 this, this.page - 1, this.itemsPerPage, this.search, this.sortDateDesc, this.sortCodeDesc,
			 null, null, this.dates[0], this.dates[1]
		)
	}

	@Watch("sortDesc")
	watchSort() {
		if (this.sortBy[0] == "date") this.sortDateDesc = this.sortDesc[0]
		if (this.sortBy[0] == "connectorId") this.sortCodeDesc = this.sortDesc[0]
		this.refresh()
	}

	@Watch("search")
	watchSearch() {
		if (this.search.length > 3) this.refresh()
	}

	@Watch('loading')
	onLoadingChanged() {
		this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
  }

	@Watch("options")
	refresh() {
		InvoiceService.getInvoices(
			 this, this.invoices, this.page - 1, this.itemsPerPage, this.search, this.sortDateDesc,
			 this.sortCodeDesc, this.lessThan, this.greaterThan, this.dates[0], this.dates[1]
		)
	}

	created() {
		this.refresh()
	}

	rowClick(item: Invoice) {
		this.invoice = item
		this.dialog = true
	}

	clearDateFilters() {
		this.dates = []
		this.refresh()
	}

	clearPriceFilters() {
		this.greaterThan = null
		this.lessThan = null
		this.refresh()
	}

}
